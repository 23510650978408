import React, { useEffect, useState } from "react";
import hero3img from "../webhero3_Easy-Resize.com.jpg";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import "./Home.scss";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();

  // const [offer, setOffer ] = useState()

  // const query = {"query":{"page":1,"count":10,"sortDir":null,"sortField":null,"filter":{"invoiceNumber":null,"ksefReferenceNumber":null,"invoicingDateFrom":"2024-04-09T22:00:00.000Z","invoicingDateTo":"2024-05-10T21:59:59.999Z","invoiceSubjectType":"subject1","subjectBy":null,"subjectTo":null}}}
  // useEffect(() => {
  //   axios.post('https://ksef-demo.mf.gov.pl/web/api/invoice/query?',
  //   headers: {
  //     'Access-Control-Allow-Origin': True,
  //     'Content-Type': 'application/json',
  //   }, 
  //   { withCredentials: true,
  //     params:query,
  //     Cookie:"CfDJ8IvX_v9tbONFuNyH8vH5lJYVgV3xgdfnEi8ILI8DXNgKLxhPPmsWUN8CBBHJ9kkXl2HYDybXpzVIEkso6DviyQ8QmsMqHzYK42w4GNmKy1lFevkRCSWW74cFNW1Zt7Dml4t9ZZt2SIK-S1hv8NqEVuaXUSvzlNpVYB1LrMNNTiOLHJqWrjNtP6J2qf4Wr9XyGQspKjD2EeptHgGNNrbVJuGJPtZuvO3KqSrVMs6bJS53W2z4K13rOE1Y6WD2vhM_hebN2T6l62tILh4WgfvjMQm1cbEuQGM34HYhF5ovaS61c-eeuRPQiLVtVvbeVpTGeTZKYoyhBxKxybi4wW7nFgxz3SWsLVHym1ZUuWrTh9ceqjosNvGGyb46DEQzpQ0LJ3rHZxO623OV2ZKlor0a5WEFC91hGMSOYPhn-AxtkRRph-WskHknW2Ypaf0pwtznEZH6BvpDdDkh5LNs9fmoDa3WNEzuwmEwDSW4fnIibRxe25wJXdqxWksdE7ZAiDCj6h0K8KpjdKtVYCAPHiwMTP4cmiN27j6lKGDMQL7Gy3FIPlFyIaSbWxwT7BoC0PzK-A4QruDrMLCswiy2R2xDPJqu4eti0qu9RAwGLzWt23QyWsXK2DQxx1zAnd_Gg7Jm2aU3zZ2mW61fqthlChwLs4ex_yQrCrMVo5CjitVXMry8XtdM0lA4Dhh1MyqZJBkj3OHZMkaDVLsC3BbGYVG7S9wCuge6qnkgRHFzGV62WrRmRTpJgBLgNpFoCkDABymJlaYyLjZcyIMTaLrlHecqgvH-1OXH5WoHZ5Awj1Cjhn2G-GZxowrXUjjWrJP-G-HqTenU9ISkneTmGdR0IM7I6Z9nfiHXDwKp_gzo-y2YdunD0H4-dwsjrSUzovojyfpGCxZaUaG_D3YVSojsP0xif3_sLNLC9ioljce2L9UwzPHemldgH9oQN5k2diHSIx_PQA"
  //   })

  //     .then(function (response) {
  //       setOffer(response.data)
  //       console.log(response);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     })
  //     .finally(function () {
  //       // always executed
  //     });
  //   }, []);


  return (
    <div className="container-home">
      <div className="box-info">
        {/* <h1 className="title">WSPÓLNIE TWÓRZMY NOWĄ WARTOŚĆ</h1> */}
        <h1 className="title">WSPÓLNIE </h1>
        <h2 className="title">TWÓRZMY</h2>
        <h2 className="title">NOWĄ</h2>
        <h2 className="title">WARTOŚĆ</h2>
        <p className="text">
          Jeśli rozważasz stworzenie nowoczesnej strony internetowej lub
          odświeżenie swojej obecnej witryny, to chętnie służę pomocą w
          realizacji Twoich celów.
        </p>
        <Button className="button-shine" onClick={() => navigate("/contact")}>
          Porozmawiajmy
        </Button>
      </div>
      <div className="box-img">
        <img src={hero3img} alt="kod" />
      </div>
    </div>
  );
};

export default Home;
